<template>
  <table-lite-custom
      :items="items"
      :fields="fields"
      :is-loading="isLoading"
  >
    <template #cell(price)="{data}">
      <strong class="text-success">{{ currencyFormat(data['item']['price']) }}</strong>
    </template>
    <template #cell(description)>
      <strong>Nạp tiền vào tài khoản</strong>
    </template>
  </table-lite-custom>
</template>

<script>

import TableLiteCustom from "@/views/components/table/TableLiteCustom.vue";
import inputHelper from "@/mixins/utils/inputHelper";

export default {
  name: 'InvoiceTopup',
  components: {
    TableLiteCustom
  },
  mixins: [inputHelper],
  props: {
    items: {
      type: Array,
      default: () => [],
      required: true
    },
    invoiceId: {
      default: undefined,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false,
      required: true
    },
  },
  data () {
    return {
      fields: [
        {
          key: 'description',
          label: 'Mô tả'
        },
        {
          key: 'price',
          label: 'Tổng nạp'
        },
      ]
    }
  },
  methods: {
  }
}

</script>
