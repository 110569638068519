<template>
  <div>
    <b-table
        responsive
        striped
        hover
        :items="items"
        :fields="fields"
        :busy.sync="isLoading"
        small
    >
      <template #head()="{ field }">
        <label class="text-center">
          <span>{{ field.label }}</span>
          <br/>
          <span>{{ field.header2 }}</span>
        </label>
      </template>
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-50"></b-spinner>
          <strong>{{ $t('table.label.loading') }}</strong>
        </div>
      </template>

      <template v-for="(field, index) in fields" #[`cell(${field.key})`]="data">
        <slot v-if="$scopedSlots[`cell(${field['key']})`]" :name="`cell(${field['key']})`" v-bind:data="data"/>
        <div v-else-if="data.value === ''">-</div>
        <div v-else-if="data.value === 0">0</div>
        <div v-else v-html="data.value"/>
      </template>
      <template #cell(index)="data">
        {{ data.index + 1 }}
      </template>
      <template #row-details="data">
        <slot name="row-details" v-bind:data="data"/>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  name: 'TableLiteCustom',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    fields: {
      type: Array,
      default: () => []
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  mounted () {
    this.fields.splice(0, 0, {
      key: 'index',
      label: '#'
    })
  },
  created() {

  }
}
</script>
