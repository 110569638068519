<template>
  <vue-skeleton-loader v-if="isLoading" rounded height="15"/>
  <span v-else>
    <slot></slot>
  </span>
</template>

<script>
export default {
  name: "SkeletonLoader",
  props: {
    isLoading: {
      type: Boolean,
      default: false,
      required: true
    }
  }
}
</script>
